@import 'variables';

cx-top-banner {
  .slick-dots {
    li {
      margin: 0 4px;
      height: 8px;
      width: 8px;
      button, button::before {
        padding: 0;
        height: 8px;
        width: 8px;
        font-size: 8px;
      }

      &.slick-active {
        button::before {
          color: var(--cgx-primary);
        }
      }
    }
  }
}

ngx-slick-carousel {
  .slick-track {
    margin-left: unset;
    margin-right: unset;
  }
  button {
    z-index: 1;
    &.slick-arrow {
      width: 48px;
      height: 48px;
      background: #FFFFFF!important;
      /* Border */

      border: 1px solid #BCC2C2;
      /* Shadow */

      box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
      border-radius: 99px;
      &::before {
        content: ' ';
        opacity: 1;
        height: 18px;
        width: 18px;
        background-size: contain;
        display: inline-block;
      }
      &:hover {
        background: #FFFFFF;
      }
    }
    &.slick-next {
      right: -10px;
      top: 110px;
      &::before {
        background-image: url('/assets/images/icons/chevron-right.svg');
      }
    }
    &.slick-prev {
      left: -10px;
      top: 110px;
      &::before {
        background-image: url('/assets/images/icons/chevron-left.svg');
      }
    }
    &.slick-disabled {
      display: none!important;
    }
  }
}

cx-past-events {
  ngx-slick-carousel {
    button {
      &.slick-prev, &.slick-next {
        @media screen and (max-width: $mobile_breakpoint) {
          top: 80px;
        }
      }
    }
  }
}
