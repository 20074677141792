/***
Reset and overrides
***/
/* general body settings */
/*
Internet Explorer 10 doesn't differentiate device width from viewport width, and thus doesn't
properly apply the media queries in Bootstrap's CSS. To address this,
you can optionally include the following CSS and JavaScript to work around this problem until Microsoft issues a fix.
*/
@-webkit-viewport {
  width: device-width;
}

@-moz-viewport {
  width: device-width;
}

@-ms-viewport {
  width: device-width;
}

@-o-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}

/* Internet Explorer 10 doesn't differentiate device width from viewport width,
and thus doesn't properly apply the media queries in Bootstrap's CSS. To address this, following CSS code applied */
@-ms-viewport {
  width: auto !important;
}

/***
General typography
***/
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  color: #444;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}

h1.block,
h2.block,
h3.block,
h4.block,
h5.block,
h6.block {
  padding-top: 10px;
  padding-bottom: 10px;
}

a {
  text-shadow: none !important;
  color: #0d638f;
}

/***
Fix link outlines after click
***/
a,a:focus, a:hover, a:active {
  outline: 0;
}

/***
General backgrounds. Can be applied to any block or panel
***/

.bg-blue {
  background-image: none !important;
  background-color: #4b8df8 !important;
  border-color:#4b8df8 !important;
  color: #fff !important;
}

.bg-red {
  background-image: none !important;
  background-color: #e02222 !important;
  border-color: #e02222 !important;
  color: #fff !important;
}

.bg-yellow {
  background-image: none !important;
  background-color: #ffb848 !important;
  border-color: #ffb848 !important;
  color: #fff !important;
}

.bg-green {
  background-image: none !important;
  background-color: #35aa47 !important;
  border-color: #35aa47 !important;
  color: #fff !important;
}

.bg-purple {
  background-image: none !important;
  background-color: #852b99 !important;
  border-color: #852b99 !important;
  color: #fff !important;
}

.bg-dark {
  background-image: none !important;
  background-color: #555555 !important;
  border-color: #555555 !important;
  color: #fff !important;
}

.be-grey {
  background-image: none !important;
  background-color: #fafafa !important;
  border-color: #fafafa !important;
}
/***
Close icon used for modal dialog and other UI element close buttons
***/
.close {
  display: inline-block;
  margin-top: 0px;
  margin-right: 0px;
  width: 9px;
  height: 9px;
  background-repeat: no-repeat !important;
  text-indent: -10000px;
  outline: none;
  background-image: url("../img/remove-icon-small.png") !important;
}

/***
General HR
***/

/*hr {*/
/*  margin: 20px 0;*/
/*  border: 0;*/
/*  border-top: 1px solid #E0DFDF;*/
/*  border-bottom: 1px solid #FEFEFE;*/
/*}*/

/***
Tools
***/
.display-none,
.display-hide {
  display: none;
}

.no-space {
  margin: 0px !important;
  padding: 0px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-bottom-15 {
  margin-bottom: 15px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-bottom-25 {
  margin-bottom: 25px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.bold {
  font-weight:600 !important;
}

.fix-margin {
  margin-left: 0px !important
}

.border {
  border: 1px solid red;
}

.inline {
  display: inline;
}

/***
Page container
***/
.page-container {
  margin: 0px;
  padding: 0px;
  position: relative;
}

.page-container:before,
.page-container:after {
  display: table;
  content: " ";
}

.page-container:after {
  clear: both;
}

.page-header-fixed .page-container {
  /* margin-top: 42px; */
}


/***
Page sidebar
***/

.page-sidebar.navbar-collapse {
  /* padding: 0; */
  position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

.page-sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  margin: 0;
  padding: 0;
}

.page-sidebar-menu > li {
  display: block;
  margin: 0;
  padding: 0;
  border: 0px;
}

.page-sidebar-menu > li.start > a {
   border-top-color: transparent !important;
}

.page-sidebar-menu > li:last-child > a,
.page-sidebar-menu > li.last > a {
   border-bottom-color: transparent !important;
}

.page-sidebar-menu > li > a {
  display: block;
  position: relative;
  margin: 0;
  border: 0px;
  padding: 10px 15px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 300;
}

.page-sidebar-fixed .page-sidebar-menu > li > a {
  -webkit-transition: all 0.2s ease;
     -moz-transition: all 0.2s ease;
       -o-transition: all 0.2s ease;
          transition: all 0.2s ease;
}

.page-sidebar-reversed.page-sidebar-fixed .page-sidebar-menu > li > a{
     -webkit-transition: none;
        -moz-transition: none;
          -o-transition: none;
             transition: none;
}

.page-sidebar-menu > li > a i {
  font-size: 16px;
  margin-right: 5px;
  text-shadow:none;
}

.page-sidebar-menu > li.break {
  margin-bottom: 20px;
}

.page-sidebar-menu > li.active > a {
  border: none;
  text-shadow:none;
}

.page-sidebar-menu > li.active > a .selected {
  display: block;
  width: 8px;
  height: 25px;
  background-image: url("../img/sidebar-menu-arrow-green.png");
  float: right;
  position: absolute;
  right:0px;
  top:8px;
}

.page-sidebar-reversed .page-sidebar-menu > li.active > a .selected {
  background-image: url("../img/sidebar-menu-arrow-green-reverse.png");
  right: auto;
  left:0;
}

.page-sidebar ul > li > a > .arrow:before {
   float: right;
   margin-top: 0px;
   margin-right: 5px;
   display: inline;
   font-size: 16px;
   font-family: FontAwesome;
   height: auto;
   content: "\f104";
   font-weight: 300;
   text-shadow:none;
}

.page-sidebar-menu > li > a > .arrow.open:before {
   float: right;
   margin-top: 0px;
   margin-right: 3px;
   display: inline;
   font-family: FontAwesome;
   height: auto;
   font-size: 16px;
   content: "\f107";
   font-weight: 300;
   text-shadow:none;
}

/* bagin: sidebar menu badges */
.page-sidebar-menu li > a > .badge {
   float: right;
   margin-top: 1px;
   margin-right: 13px;
}

/* end: sidebar menu badges */

.page-sidebar-menu .sub-menu {
  padding: 0;
}

.page-sidebar-menu > li > ul.sub-menu {
  display: none;
  list-style: none;
  clear: both;
  margin: 8px 0px 8px 0px;
}

.page-sidebar-menu > li.active > ul.sub-menu {
  display: block;
}

.page-sidebar-menu > li > ul.sub-menu > li {
  background: none;
  margin: 0px;
  padding: 0px;
  margin-top: 1px !important;
}

.page-sidebar-menu > li > ul.sub-menu > li > a {
  display: block;
  margin: 0px 0px 0px 0px;
  padding: 5px 0px;
  padding-left: 44px !important;
  text-decoration: none;
  font-size: 14px;
  font-weight: 300;
  background: none;
}

/* 3rd level sub menu */
.page-sidebar-menu > li > ul.sub-menu  > li ul.sub-menu {
  display: none;
  list-style: none;
  clear: both;
  margin: 0px 0px 0px 0px;
}

.page-sidebar-menu > li > ul.sub-menu  li > a > .arrow:before   {
   float: right;
   margin-top: 1px;
   margin-right: 20px;
   display: inline;
   font-size: 16px;
   font-family: FontAwesome;
   height: auto;
   content: "\f104";
   font-weight: 300;
   text-shadow:none;
}

.page-sidebar-menu > li > ul.sub-menu  li > a > .arrow.open:before {
   float: right;
   margin-top: 1px;
   margin-right: 18px;
   display: inline;
   font-family: FontAwesome;
   height: auto;
   font-size: 16px;
   content: "\f107";
   font-weight: 300;
   text-shadow:none;
}

.page-sidebar-menu > li.active > ul.sub-menu > li.active ul.sub-menu {
  display: block;
}

.page-sidebar-menu > li > ul.sub-menu > li  ul.sub-menu li {
  background: none;
  margin: 0px;
  padding: 0px;
  margin-top: 1px !important;
}

.page-sidebar-menu > li > ul.sub-menu  li > ul.sub-menu > li > a {
  display: block;
  margin: 0px 0px 0px 0px;
  padding: 5px 0px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 300;
  background: none;
}

.page-sidebar-menu > li > ul.sub-menu > li > ul.sub-menu > li > a {
  padding-left: 60px;
}

.page-sidebar-menu > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu > li > a {
  padding-left: 80px;
}

.page-sidebar-menu > li.active > ul.sub-menu > li.active ul.sub-menu > li.active ul.sub-menu {
  display: block;
}


.page-sidebar-menu > li > ul.sub-menu  li > ul.sub-menu > li > a > i {
  font-size: 13px;
}




/***
Sidebar Search
***/

.page-sidebar .sidebar-search {
  padding:0;
  margin: 0;
}

.page-sidebar .header-responsive-search {
  display: none;
}

.page-sidebar .sidebar-search .form-container {
  margin: 15px 20px 15px 20px;
  height: 35px;
  padding-top: 7px;
}

.page-sidebar .sidebar-search .form-container .submit {
  display: block;
  float: right;
  margin-top: 3px;
  width: 13px;
  height: 15px;
  background-repeat: no-repeat;
  box-shadow: none;
  border: 0px;
  padding: 0px;
  outline: none !important;
}

.page-sidebar .sidebar-search .form-container input[type="text"] {
  margin: 0px;
  width: 165px;
  border: 0px;
  padding: 0 !important;
  font-size: 14px !important;
  box-shadow: none !important;
  font-size: 14px;
  font-weight: normal;
}

.page-sidebar .sidebar-search .form-container input[type="text"]:focus {
  outline: none !important;
}




/***
Page content
***/
.page-content {
  margin-top: 0px;
  padding: 0px;
}

/***
Page title
***/
.page-title {
  padding: 0px;
  font-size: 30px;
  letter-spacing: -1px;
  display: block;
  color: #666;
  margin: 0px 0px 15px 0px;
  font-weight: 300;
  font-family: 'Open Sans', sans-serif;
}

.page-title small {
  font-size: 14px;
  letter-spacing: 0px;
  font-weight: 300;
  color: #888;
}

/***
Page breadcrumb
***/

.ie8 .row .page-breadcrumb.breadcrumb > li {
  margin-right: 1px;
}

.page-content .page-breadcrumb.breadcrumb {
  -webkit-border-radius: 0px;
     -moz-border-radius: 0px;
          border-radius: 0px;
  box-shadow: none;
  padding-right: 30px;
  padding-left: 8px;
  margin-top: 15px;
  margin-bottom: 25px;
  border:0px !important;
  background-color: #eee;
}

.page-content .page-breadcrumb.breadcrumb > li > a,
.page-content .page-breadcrumb.breadcrumb > li > i,
.page-content .page-breadcrumb.breadcrumb > li > span {
  color: #333;
  font-size: 14px;
  text-shadow:none;
}

.page-content .page-breadcrumb.breadcrumb > li > i {
  color: #666;
}

.page-content .page-breadcrumb.breadcrumb > li+li:before {
  display: none;
}

/***
Footer
***/

.footer {
  padding: 8px 20px 5px 20px;
  font-size: 12px;
}

.footer:after,
.footer:before {
  content: "";
  display: table;
  line-height: 0;
}

.footer:after {
  clear: both;
}

.footer .footer-inner {
  float: left;
  display: inline-block;
}

.footer .footer-tools {
  float: right;
  display: inline-block;
}

.footer .footer-tools .go-top {
  display: block;
  text-decoration: none;
  cursor: pointer;
  margin-top: -2px;
  margin-right: 0px;
  margin-bottom: 0px;
  font-size: 16px;
  padding: 0px 6px 0px 6px;
}

.footer .footer-tools .go-top i {
  font-size: 22px;
  margin-bottom: 5px;
}



@media (min-width: 992px) {

  /***
  Page sidebar
  ***/
  .page-sidebar {
    float: left;
    position: relative;
    margin-right: -100%;
    }

    .page-sidebar.navbar-collapse {
      max-height: none !important;
    }

    /***
    Page content
    ***/
  .page-content-wrapper {
    float: left;
    width: 100%;
  }

    .page-content {
    margin-top: 0px;
  }

  .page-content.no-min-height {
    min-height: auto;
  }

  /***
  Footer
  ***/
  .footer {
    clear: left;
  }

  /***
    Fixed Sidebar
    ***/
  .page-sidebar-fixed .page-content {
    min-height: 600px;
  }

  .page-sidebar-fixed .page-sidebar {
      position: fixed !important;
      margin-left: 0;
    top: 41px;
  }

  .page-sidebar-fixed ul.page-sidebar-menu > li.last {
    margin-bottom: 15px !important;
  }

  .page-sidebar-fixed.page-sidebar-hover-on .page-sidebar {
    z-index: 10000;
    width: 35px;
  }

  .page-sidebar-fixed.page-sidebar-hover-on .page-sidebar .selected {
    display: none;
  }

  .page-sidebar-fixed.page-sidebar-hover-on .page-content {
    margin-left: 35px;
  }

  .page-sidebar-fixed.page-sidebar-hover-on .footer {
    margin-left: 35px;
  }

  .page-sidebar-fixed .page-sidebar-closed .page-sidebar .sidebar-search .submit,
  .page-sidebar-fixed .page-sidebar .sidebar-toggler {
    -webkit-transition: all  0.3s ease;
         -moz-transition: all  0.3s ease;
           -o-transition: all  0.3s ease;
              transition: all  0.3s ease;
  }

  .page-sidebar-fixed.page-sidebar-reversed .page-sidebar-closed .page-sidebar .sidebar-search .submit,
  .page-sidebar-fixed.page-sidebar-reversed .page-sidebar .sidebar-toggler {
    -webkit-transition: none;
         -moz-transition: none;
           -o-transition: none;
              transition: none;
  }

  .page-sidebar-hovering {
    overflow: hidden !important;
  }

  .page-sidebar-hovering .sub-menu,
  .page-sidebar-hovering span.title,
  .page-sidebar-hovering span.arrow {
    display: none !important;
  }

  .page-sidebar-hovering .submit {
    opacity: 0;
    width: 0 !important;
    height: 0 !important;
  }

  /***
  Fixed Footer
  ***/

  .page-footer-fixed .footer {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 10000;
    bottom: 0;
  }

  .page-footer-fixed .page-container {
    margin-bottom: 32px;
  }

  .page-footer-fixed.page-sidebar-fixed .footer {
    margin-left: 0 !important;
  }

  /***
  Fixed Sidebar
  ***/

  .page-sidebar-fixed .footer {
    margin-left: 225px;
    background-color: #fff;
    padding: 8px 20px 5px 20px;
  }

  .page-sidebar-fixed .footer .footer-inner {
    color: #333;
  }

  .page-sidebar-fixed.page-sidebar-closed .footer {
    margin-left: 35px;
  }

  .page-sidebar-fixed .footer .footer-tools .go-top {
    background-color: #666;
  }

  .page-sidebar-fixed .footer .footer-tools .go-top i {
    color: #ddd;
  }

  /***
  Boxed Layout
  ***/

  .page-boxed .header .navbar-brand {
      margin-left: 0px !important;
      width: 226px;
  }

  .page-boxed .header .navbar-brand img {
    margin-left: 10px;
  }

  .page-boxed .header .navbar-nav {
    margin-right: 0px;
  }

  .page-boxed .footer {
      padding: 8px 0 5px 0;
  }

  .page-boxed.page-sidebar-fixed .footer {
    padding-right: 20px;
    padding-left: 20px;
  }

  /***
  Sidebar Reversed
  ***/

  .page-sidebar-reversed .page-sidebar {
    float: right;
    margin-right: 0;
    margin-left: -100%;
  }

  .page-sidebar-reversed.page-sidebar-fixed .page-sidebar {
    margin-left: -225px;
  }

    .page-sidebar-reversed .page-content {
      margin-left: 0;
      margin-right: 225px;
    }

    .page-sidebar-reversed.page-sidebar-fixed .page-sidebar-wrapper {
    position: relative;
    float: right;
  }

  .page-sidebar-reversed.page-sidebar-fixed .footer {
    margin-left: 0;
    margin-right: 225px;
    padding: 8px 20px 5px 20px;
  }

  .page-sidebar-reversed.page-sidebar-fixed.page-footer-fixed .footer {
    margin-left: 0;
    margin-right: 0;
  }

  .page-sidebar-reversed.page-sidebar-fixed.page-sidebar-hover-on .page-content {
    margin-left: 0;
    margin-right: 35px;
  }

  .page-sidebar-reversed.page-sidebar-fixed.page-sidebar-hover-on .footer {
    margin-right: 35px;
  }

  /***
  Sidebar Closed
  ***/

  .page-sidebar-closed .page-sidebar .sidebar-toggler {
      margin-left: 3px;
  }

  .page-sidebar-closed .page-sidebar .sidebar-search .form-container {
    width: 29px;
    margin-left: 3px;
  }

  .page-sidebar-closed .page-sidebar .sidebar-search .form-container .input-box {
    border-bottom: 0 !important;
  }

  .page-sidebar-closed .page-sidebar .sidebar-search .form-container input[type="text"] {
    display: none;
  }

  .page-sidebar-closed .page-sidebar .sidebar-search .form-container .submit {
    margin-top: 5px !important;
    margin-left: 7px !important;
    margin-right: 7px !important;
    display: block !important;
  }

  .page-sidebar-closed .page-sidebar .sidebar-search.open .form-container {
    width: 255px;
    position: relative;
    z-index: 1;
    padding-top: 0px;
  }

  .page-sidebar-closed .page-sidebar .sidebar-search.open .form-container input[type="text"] {
    margin-top: 7px;
    margin-left: 8px;
    padding-left: 10px;
    padding-bottom: 2px;
    width: 185px;
    display: inline-block !important;
  }

  .page-sidebar-closed .page-sidebar .sidebar-search.open .form-container .submit {
    display: inline-block;
    width: 13px;
    height: 13px;
    margin: 11px 8px 9px 6px !important;
  }

  .page-sidebar-closed .page-sidebar .sidebar-search.open .form-container .remove {
    background-repeat: no-repeat;
    width: 11px;
    height: 11px;
    margin: 12px 8px 9px 8px !important;
    display: inline-block !important;
    float: left !important;
  }

  .page-sidebar-closed .page-sidebar-menu > li > a .selected {
    right: -3px !important;
  }

  .page-sidebar-closed .page-sidebar-menu > li > a > .title,
  .page-sidebar-closed .page-sidebar-menu > li > a > .arrow {
    display: none !important;
  }

  .page-sidebar-closed .page-sidebar .sidebar-toggler {
    margin-right: 3px;
  }

  .page-sidebar-closed .page-sidebar .sidebar-search {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .page-sidebar-closed .page-sidebar-menu {
    width: 35px !important;
  }

  .page-sidebar-closed .page-sidebar-menu > li > a {
    padding-left: 7px;
  }

  .page-sidebar-fixed.page-sidebar-closed .page-sidebar-menu > li > a {
    -webkit-transition: all 0.2s ease;
       -moz-transition: all 0.2s ease;
         -o-transition: all 0.2s ease;
            transition: all 0.2s ease;
  }

  .page-sidebar-reversed.page-sidebar-fixed.page-sidebar-closed .page-sidebar-menu > li > a {
      -webkit-transition: none;
          -moz-transition: none;
            -o-transition: none;
               transition: none;
  }

  .page-sidebar-closed .page-sidebar-menu > li:hover {
    width: 236px !important;
    position: relative !important;
    z-index: 2000;
    display: block !important;
  }

  .page-sidebar-closed .page-sidebar-menu > li.sidebar-toggler-wrapper:hover,
  .page-sidebar-closed .page-sidebar-menu > li.sidebar-search-wrapper:hover {
    width: 35px !important;
  }

  .page-sidebar-closed .page-sidebar-menu > li:hover .selected {
    display: none;
  }

  .page-sidebar-closed .page-sidebar-menu > li:hover > a > i {
    margin-right: 10px;
  }

  .page-sidebar-closed .page-sidebar-menu > li:hover .title {
    display: inline !important;
  }

  .page-sidebar-closed .page-sidebar-menu > li > .sub-menu {
    display: none !important;
  }

  .page-sidebar-closed .page-sidebar-menu > li:hover > .sub-menu {
    width: 200px;
    position: absolute;
    z-index: 2000;
    left: 36px;
    margin-top: 0;
    top: 100%;
    display: block !important;
  }

  .page-sidebar-closed .page-sidebar-menu > li:hover > .sub-menu > li > .sub-menu,
  .page-sidebar-closed .page-sidebar-menu > li:hover > .sub-menu > li > .sub-menu > li > .sub-menu {
    width: 200px;
  }

  /* 2rd level sub menu*/
  .page-sidebar-closed .page-sidebar-menu > li:hover > .sub-menu > li > a {
    padding-left: 15px !important;
  }

  /* 3rd level sub menu*/
  .page-sidebar-closed .page-sidebar-menu > li > ul.sub-menu > li > .sub-menu > li > a {
    padding-left: 30px !important;
  }

  /* 4rd level sub menu*/
  .page-sidebar-closed .page-sidebar-menu > li > ul.sub-menu > li > .sub-menu > li > .sub-menu > li > a {
    padding-left: 45px !important;
  }

  /* sidebar container */

  .page-sidebar-closed  .page-sidebar {
    width: 35px;
  }

  .page-sidebar-closed  .page-content {
    margin-left: 35px !important;
  }

  /***
  Sidebar Reversed & Sidebar Closed
  ***/

  .page-sidebar-reversed.page-sidebar-closed  .page-sidebar {
    margin-left: -35px;
    width: 35px;
  }

  .page-sidebar-reversed.page-sidebar-closed  .page-content {
    margin-left: 0 !important;
    margin-right: 35px !important;
  }

  .page-sidebar-reversed.page-sidebar-closed .page-sidebar-menu > li:hover {
    margin-left: -201px;
  }

  .page-sidebar-reversed.page-sidebar-closed .page-sidebar-menu > li.sidebar-toggler-wrapper:hover,
  .page-sidebar-reversed.page-sidebar-closed .page-sidebar-menu > li.sidebar-search-wrapper:hover {
    margin-left: 0;
  }

  .page-sidebar-reversed.page-sidebar-closed .page-sidebar .sidebar-search.open .form-container {
    margin-left: -225px;
  }

  .page-sidebar-reversed.page-sidebar-closed .page-sidebar .sidebar-search.open .form-container .submit {
    margin: 11px 8px 9px 12px !important;
    float: left !important;
  }

  .page-sidebar-reversed.page-sidebar-closed .page-sidebar .sidebar-search.open .form-container .remove {
    margin: 12px 6px 9px 8px !important;
    float: right !important;
  }

  .page-sidebar-reversed.page-sidebar-closed .page-sidebar-menu > li:hover > .sub-menu {
    left:auto;
    right: 36px;
  }

  .page-sidebar-reversed.page-sidebar-fixed.page-sidebar-closed .footer {
    margin-right: 35px;
  }

}


/********************** custom *********************/
.canvas-control{
  position:fixed;
  bottom:0;
  left:0;
  color: #28b779;
    width: 130px;
    line-height: 35px;
  text-align:center;
  z-index:101;
}
.booking .canvas-control{
  line-height: 35px;
  bottom : 10px;
  right : 10px;
  left : auto;
  color: #555;
  width : 90px;
  height : 90px;
  border-radius : 50px;
  -webkit-box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 1);
    -moz-box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 1);
    box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 1);
  background-color : white;
}
#zoomSlider{
  display:inline-block;
  height : 15px;
  width : 150px;
}

.booking .zoom{
  width:280px;
  position: fixed;
    right: 30px;
    bottom: 35px;
    border: none;
}
.booking .control .fa{
  font-size : 25px;
  margin : 0px -4px;
  color : #999;
}
.booking .ui-widget-content .ui-state-default {
    width: 25px;
    height: 25px;
  border-radius: 20px;
  -webkit-box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 1);
    -moz-box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 1);
    box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 1);
  border: 1px solid #999;
    background: #fefefe;
}
.booking .canvas-control .fa {
    font-size: 35px;
}
.canvas-control .left-middle-right .fa:nth-child(2){
  margin-right:10px;
  margin-left:10px;
}

.canvas-control .fa{
  font-size:20px;
  cursor:pointer;
  opacity:0.5;
}
.canvas-control .fa:hover{
  opacity:1;
}
.canvas-control .fa:active{
  transform: translateY(4px);
}
.canvas-control .plus-minus{
  width:100%;
  margin:auto;
}
.canvas-control .plus-minus span{
  margin: 12px 0px;
    display: block;
}


.zoom-container{
  position:fixed;
  bottom:30px;
  right:0px;
  color: #333;
    width: 80px;
    line-height: 45px;
  margin:0px 0px;
  text-align:center;
  z-index:101;
}

.BGSection{
  position:fixed;
  bottom:10px;
  right:100px;
  color: #333;
  margin:0px 0px;
  z-index:101;
}

.BGSection img{
  /*width:100px;*/
  height:250px;
}

.zoom-container .fa{
  font-size:30px;
  cursor:pointer;
  opacity:0.5;
  width : 100%;
}

.zoom-container .fa:hover{
  opacity:1;
}

.zoom-container .fa:active{
  transform: translateY(4px);
}








/********************** custom *********************/

    #topRightInfo {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    svg {
      /*background-image: url('../img/background.png');*/
      background: white;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    form,
    #chartDesignerWrapper,
    .chartdesigner {
      height: 100%;
    }
    textarea:focus,
    input[type="text"]:focus,
    input[type="password"]:focus,
    input[type="datetime"]:focus,
    input[type="datetime-local"]:focus,
    input[type="date"]:focus,
    input[type="month"]:focus,
    input[type="time"]:focus,
    input[type="week"]:focus,
    input[type="number"]:focus,
    input[type="email"]:focus,
    input[type="url"]:focus,
    input[type="search"]:focus,
    input[type="tel"]:focus,
    input[type="color"]:focus,
    .uneditable-input:focus {
      border-color: rgba(0, 0, 200, 0.8);
      /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(135, 168, 0, 0.8); */
      outline: 0 none;
    }
    .chartdesigner .editableText:focus {
      outline: none;
      color: black;
    }
    label {
      font-weight: normal;
    }
    a {
      color: #88aa00;
    }
    a:hover {
      color: #5f7700;
    }
    #propertiesTooltip {
      position: absolute;
      background-color: rgba(60, 60, 60, 1);
      /* border-radius: 5px; */
      padding: 3px;
      z-index: 1000;
      white-space: nowrap;
      max-width: 320px;
      width: 320px;
      display: none;
    }
    .strong {
      font-weight: bold;
    }
    .btn {
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }
    #chartNameContainer {
      margin: 20px;
      position: fixed;
      z-index: 999;
    }
    #chartNameContainer .readOnly {
      margin: 0;
      padding: 0;
      font-size: 2em;
    }
    #chartNameContainer input {
      font-size: 20px;
      padding: 2px;
      border-radius: 5px;
      border: 1px solid #88aa00;
      width: 270px;
    }
    #chartNameContainer input[readonly="readonly"] {
      border: 1px solid transparent;
      background: transparent;
    }
    #backButton {
      margin: 20px;
      top: 35px;
      position: fixed;
      z-index: 999;
      font-size: 1.25em;
    }
    #mainModeButtons {
      width: 800px;
    }
    #mainModeButtons .btn {
      width: 160px !important;
      font-size: 18px;
    }
    #drawModeButtonsContainer {
      width: 1000px;
    }
    #labelModeButtonsContainer {
      width: 440px;
    }
    #categoryModeButtonsContainer {
      width: 125px;
    }
    #shapeModeButtonsContainer,
    #generalAdmissionModeButtonsContainer {
      width: 320px;
    }
    #tableModeButtonsContainer {
      width: 320px;
    }
    #backgroundModeButtonsContainer {
      width: 560px;
    }
    #backgroundModeButtonsContainer #backgroundImageSpinner {
      display: none;
      position: relative;
      right: 10px;
      top: 12px;
      float: right;
    }
    #backgroundModeButtonsContainer #backgroundImageSpinner .fa {
      color: #5f7700;
    }
    #backgroundModeButtonsContainer #backgroundImageUrlError {
      display: none;
    }

    .modeButtonsContainer[data-level="1"] {
      margin-top: 64px;
    }
    .modeButtonsContainer[data-level="2"] {
      margin-top: 94px;
    }
    #topRightInfo {
      position: fixed;
      right: 10px;
      top: 20px;
      text-align: right;
    }
    #PropertyPanel,#listCategories,#settingLabel, #StandAreaPanel, #InfoBooking, #InfoStandSection{
      opacity : 0.8;
      position: fixed;
      background-color: #eee;
      padding: 3px;
      z-index: 1000;
      white-space: nowrap;
      max-width: 330px;
      width: 330px;
    }
    #PropertyPanel,#listCategories,#settingLabel{
      right:10px;
      top : 30px;
    }
    #InfoBooking{
      opacity : 1;
      right:10px;
      top : 7px;
      border-radius:5px;
      z-index: 2000;
    }
    #StandAreaPanel{
      left : 10px;
      top : 150px;
    }
    #InfoStandSection{
      opacity : 1;
      top: 30px;
      right: 10px;
    }
    #InfoStandSection input, #InfoBooking input, #StandAreaPanel input{
      width : 70px;
    }
    #listCategories{
        overflow: scroll;
      max-height: 450px;
    }
    #StandAreaPanel .panelTitle, #InfoBooking .stand.panelTitle{
      width : 170px;
    }
    div#InfoBooking.seat div.stand, div#InfoBooking.stand div.seat{
      display:none;
    }
    .panelTitle{
      width: 100px;
      font-size: 20px;
      font-weight: bold;
      margin: auto;
      margin-top: 10px;
      margin-bottom:5px;
    }
    #editContainer:hover{
      background-color : #8a0;
      color : #fff;
    }
    #editContainer,#editContainer:active{
      background-color : #526700;
      color : #fff;
    }

    .kindRectTable{
      height:35px;
    }
    #seatStyle{
      margin-left:35px;
    }
    #rowStyle{
      margin-left:38px;
    }
    #oneRowValue {
      margin-left:45px;
    }
    .optionValue{
      width:120px;
      height:25px;
      text-align: center;
    }
    .detailSetting{
      margin-left: 30px;
    }
    .detailSetting > div{
      display: flex;
      margin-top:5px;
    }
    .optionlabel{
      width:120px;
    }
    .detailSetting .btn-default{
      padding : 0px 0px ! important;
    }
    .detailSetting .btn-default span, .detailSetting .btn-default i{
      margin: 3px 10px 3px 10px;
    }
    .select{
      background-color:#ccc ! important;
    }


    /* #settingLabel{
      left:5400px;
      top:5200px;
    } */

    .slider{
      width: 100px;
      height: 7px;
    }
    .ui-state-default, .ui-widget-content .ui-state-default{
      cursor : pointer;
      width: 15px;
      height: 15px;
    }
    .control {
      padding: 5px 14px;
      margin: 2px;
      min-width: 200px;
      font-size: 1.2em;

      border: 2px solid #aaa;
      color: #000;
    }
    .control .sliderLabel {
      font-size: 0.9em;
      margin-right: 6px;
      display: inline-block;
      width: 100px;
    }
    .control .valueLabel {
      font-size: 0.8em;
      margin-left: 6px;
    }
    .control .infoLabel {
      font-size: 0.9em;
      margin-right: 6px;
      display: inline-block;
      width: 200px;
      /*text-align: center;*/
    }
    #InfoBooking .control .infoLabel{
      width : 65px;
    }
    #book span{
      margin-left: 100px;
    }
    #addToCart span{
      margin-left: 95px;
    }
    .control .fa {
      margin: 0 10px 0 0;
      vertical-align: middle;
    }
    .control:hover {
      cursor: pointer;
    }
    .hoverEffect:hover {
      background-color: #fff;
      color:#000;
    }
    #align{
      display : flex;
    }
    #align > div{
      width : 100px;
      text-align : center;
    }

    #venueTypeSwitcherModal h5 small {
      font-size: 0.9em;
    }
    .categoryButton {
      /* background-color: white; */
      padding: 7px 14px 5px;
      margin: 2px;
      min-width: 100px;
      border: 2px solid #aaa;
      color: #333;
    }
    .categoryButton .categoryLabel {
      display: inline-block;
      white-space: normal;
      width: 115px;
      margin-left : 20px;
    }
    .categoryButton .categoryPrice {
      display: inline-block;
      white-space: normal;
      width: 70px;
      margin-left : 2px;
    }
    .categoryButton .categoryKey {
      display: inline-block;
    }
    .categoryButton .readOnlyModeControls,
    .categoryButton .editModeControls {
      display: block;
      vertical-align: top;
    }
    .categoryButton .colorSquareWrapper {
      display: inline-block;
      vertical-align: top;
    }
    .categoryButton:hover {
      background-color: #fff;
      color:#aaa;
      cursor: pointer;
    }
    .categoryButton .editBtns {
      float: right;
    }
    .categoryButton .editBtns i {
      margin: 0 0 0 10px;
    }
    .categoryButton .categoryColorPickerBtn {
      border-radius: 5px;
    }
    #contextHelp {
      position: fixed;
      z-index: 999;
      bottom: 0;
      width: 100%;
      padding: 6px 52px;
    }
    #contextHelp #helpText,
    #contextHelp #helpIcon {
      line-height: 20px;
    }
    #contextHelp #helpIcon {
      vertical-align: middle;
      color: #88aa00;
    }
    .edit-section-content-button,
    .edit-section-content-button:hover {
      background-color: #88aa00;
      color: white;
    }
    #zoomAndPanControl {
      bottom: 36px;
      margin-left: 20px;
      padding: 0;
      position: fixed;
      z-index: 999;
      width: 100px;
      height: 100px;
      color: #88aa00;
      border-radius: 50%;
    }
    #zoomAndPanControl .fa {
      position: absolute;
      width: 40px;
      height: 40px;
      font-size: 24px;
      text-align: center;
      cursor: pointer;
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -o-user-select: none;
      user-select: none;
    }
    #zoomAndPanControl .fa:active,
    #zoomAndPanControl .fa.active {
      color: #5f7700;
    }
    #zoomAndPanControl .fa#panCenter {
      top: 58%;
      margin-top: -20px;
      left: 50%;
      margin-left: -20px;
    }
    #zoomAndPanControl .fa#panUp {
      top: 8px;
      left: 50%;
      margin-left: -20px;
    }
    #zoomAndPanControl .fa#panDown {
      bottom: -8px;
      left: 50%;
      margin-left: -20px;
    }
    #zoomAndPanControl .fa#panLeft {
      top: 58%;
      margin-top: -20px;
      left: 0;
    }
    #zoomAndPanControl .fa#panRight {
      top: 58%;
      margin-top: -20px;
      right: 0;
    }
    #zoomAndPanControl .fa#zoomIn {
      top: -80px;
      left: 50%;
      margin-left: -20px;
    }
    #zoomAndPanControl .fa#zoomOut {
      top: -40px;
      left: 50%;
      margin-left: -20px;
    }
    .saveMessage {
      display: none;
    }
    #actionMenu{
      margin : 15px;
      background-color : #f3faff;
    }
    #actionMenu > ul > li > a{
      font-size : 15px;
      border-top-right-radius: 6px;
      padding: 0 25px;
      background-color: #e5f3fe;
      color: #0d638f;
    }
    .nav-tabs>li.active>a, .nav-tabs>li>a:hover, .nav-tabs>li.active>a:focus {
      color: #555;
      cursor: default;
      background-color: #fff !important;
      border: 1px solid #ddd;
      border-bottom-color: transparent;
    }
    .tab-content{
      margin-top : 5px;
    }
    .tab-content .tab-pane > ul {
        padding: 0 0 0 5px;
      height: 100%;
      display: table;
      /* background: #fff; */
    }
    .tab-content .tab-pane > ul > li{
      display: table-cell;
      vertical-align: middle;
      padding: 0 20px;
      position: static;
    }
    .tab-content ul li a, .tab-content ul li a > i, .tab-content ul li label{
      font-size : 14px;
      color: #676c7b;
    }
    .tab-content ul li input{
      margin-right:5px;
    }
    .tab-content ul li input[type="number"]{
      width:70px;
    }
    .tab-content .tab-pane > ul > li a:hover, .tab-content .tab-pane > ul > li a > i:hover,.tab-content .tab-pane > ul > li.active a, .tab-content .tab-pane > ul > li.active a > i{
      color: #6167E0;
    }
    .dropdown-menu>.active>a, .dropdown-menu>.active>a:hover, .dropdown-menu>.active>a:focus {
      color: #fff;
      text-decoration: none;
      background-color: #e5f3fe;
      outline: 0;
    }
    .dropdown{
      position: relative !important;
    }
    .buttonSelect{
      background-color: #ebebeb;
      border-color: #adadad;
    }

    .rectTable{
      height: 70px;
    }
    .kindRectTable button{
      padding : 2px 5px;
    }
    .kindRectTable span{
      width: 100%;
      height: 46px;
      display: inline-block;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url("../img/recttable1.png");
    }

    .tableKind_1 > span{
      background-image: url("../img/recttable1.png");
    }

    .tableKind_2 > span{
      background-image: url("../img/recttable2.png");
    }

    .tableKind_3 > span{
      background-image: url("../img/recttable3.png");
    }

    .tableKind_4 > span{
      background-image: url("../img/recttable4.png");
    }



/* booking */

  .canvas-control-booking{
    position:fixed;
    bottom:0;
    left:0;
    color: #28b779;
    width: 30px;
    line-height: 35px;
    margin:0 0 0px 20px;
    text-align:center;
    z-index:101;
  }
  .canvas-control-booking .left-middle-right .fa:nth-child(2){
    margin-right:10px;
    margin-left:10px;
  }

  .canvas-control-booking .fa{
    font-size:30px;
    cursor:pointer;
    opacity:0.5;
    width:100%;
  }
  .canvas-control-booking .fa:hover{
    opacity:1;
  }
  .canvas-control-booking .fa:active{
    transform: translateY(4px);
  }
  .canvas-control-booking .plus-minus{
    width:100%;
    margin:auto;
  }
  .canvas-control-booking .plus-minus span{
    margin:0 12px;;
  }

  .control p{
    margin:0px;
  }
  .btngroup button{
    float: right;
    height:25px;
    padding: 1px 6px;
    margin: 2px 10px;
  }

  #thumbView{
    position:fixed;
    top:0;
    left:0;
    margin:1% 1%;
    /* display: none; */
  }

  #thumbView svg{
    background : #e7e7e7;
  }

.move{
  cursor : default;
}
  .move:active{
    cursor : default;
  }
  .section-container{
    cursor : pointer;
  }
  .section-container:active{
    cursor : pointer;
  }

  .zoom-in{
    cursor : zoom-in;
  }
  .zoom-out{
    cursor : zoom-out;
  }
  .pointer{
    cursor : pointer;
  }
  .default{
    cursor : auto;
  }
.cursor-grabbing{
  cursor : grab;
}
.cursor-grabbing:active{
  cursor : grabbing;
}
