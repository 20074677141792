:root {
  --cgx-primary: #00C0FF;
  --cgx-primary-light: #00C0FF12;
  --cgx-primary-inverse: #ffffff;
}


/* primary */
.btn.btn-primary{
  background-color: var(--cgx-primary);
  color: var(--cgx-primary-inverse);
  border-color: var(--cgx-primary);
}
.btn-check:active+.btn.btn-primary, .btn-check:checked+.btn.btn-primary, .btn.btn-primary.active, .btn.btn-primary.show, .btn.btn-primary:active:not(.btn-active), .btn.btn-primary:focus:not(.btn-active), .btn.btn-primary:hover:not(.btn-active), .show>.btn.btn-primary{
  background-color: var(--cgx-primary) !important;
  color: var(--cgx-primary-inverse) !important;
  border-color: var(--cgx-primary) !important;
}

/* outline primary */
.btn.btn-outline.btn-outline-primary{
  color: var(--cgx-primary);
  border-color: var(--cgx-primary);
  background-color: var(--cgx-primary-inverse);
}
.btn-check:active+.btn.btn-outline.btn-outline-primary, .btn-check:checked+.btn.btn-outline.btn-outline-primary, .btn.btn-outline.btn-outline-primary.active, .btn.btn-outline.btn-outline-primary.show, .btn.btn-outline.btn-outline-primary:active:not(.btn-active), .btn.btn-outline.btn-outline-primary:focus:not(.btn-active), .btn.btn-outline.btn-outline-primary:hover:not(.btn-active), .show>.btn.btn-outline.btn-outline-primary{
  color: var(--cgx-primary-inverse) !important;
  border-color: var(--cgx-primary) !important;
  background-color: var(--cgx-primary) !important;
}
.bg-primary{
  background-color: var(--cgx-primary) !important;
  color: var(--cgx-primary-inverse) !important;
}

.title-orn{
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #222222;
  position: relative;
  padding-left: calc(24px);
  margin-bottom: 24px;
  &:before{
    content: url('/assets/images/icons/title-ornament.svg');
    position: absolute;
    left: 0;
  }
}

.required:after {
  content: "*";
  position: relative;
  font-size: inherit;
  color: #E6353D;
  padding-left: 0.25rem;
  font-weight: 600;
}
.cx-form{
  background-color: #F6F8FA;
  padding: 12px;
  border-radius: 8px;
}
.form-label{
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: #222222;
}
input {
  font-size: inherit;
}
input.form-control,
select.form-select,
textarea.form-control {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: -0.02em;
  padding: 12px 16px;
  &::placeholder{
    color: #616161;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: -0.02em;
  }
}
.cx-card{
  background-color: #ffffff;
  min-height: 200px;
  border-radius: 16px;
  padding: 24px;
  height: max-content;
}
.cursor-pointer{
  cursor: pointer;
}
.fs-7{
  font-size: 0.75rem !important;
}
.form-check-input:checked{
  background-color: var(--cgx-primary);
  border-color: var(--cgx-primary);
}
.form-check-input:focus{
  box-shadow: none;
}
.border-dashed{
  border-style: dashed !important;
}

.custom-datepicker{
  .ngb-dp-weekdays{
    background: transparent;
    border: none;
  }
  .ngb-dp-weekday{
    flex: 1 0 0%;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: #222222;
    font-style: normal;
  }
  .ngb-dp-day{
    flex: 1 0 0%;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    .btn-light{
      font-weight: 600;
      font-size: 12px;
      line-height: 150%;
      letter-spacing: -0.02em;
      color: #222222;
      width: 2.5rem;
      height: 2.5rem;
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }
}
.g-recaptcha{
  width: desired_width;

  border-radius: 4px;
  border-right: 1px solid #d8d8d8;
  overflow: hidden;
}
textarea:focus, input[type="text"]:focus, input[type="password"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="time"]:focus, input[type="week"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="color"]:focus, .uneditable-input:focus{
  border-color: transparent !important;
}

ngb-accordion {
  // --bs-accordion-border-radius: 8px!important;
  .accordion-item {
    button {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 120%;
      /* identical to box height, or 22px */

      letter-spacing: -0.02em;

      /* Text/Body */

      color: #222222;
    }
    .accordion-button:not(.collapsed) {
      background-color: #FFFFFF;
      box-shadow: none;
      border-bottom: 1px dashed #BCC2C2;

      &::after {
        background-image: url('/assets/images/icons/chevron-down.svg');
      }
    }
    .accordion-button::after {
      background-image: url('/assets/images/icons/chevron-down.svg');
    }
    .accordion-button:focus {
      box-shadow: none;
    }
    .accordion-body {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      /* identical to box height, or 18px */

      letter-spacing: -0.02em;

      /* Text/Caption */

      color: #717171;
    }
  }

  &.cx-accordion {
    .accordion-item {
      border: none;
      border-radius: 16px;
      .accordion-header {
        .accordion-button {
          border: 1px solid #BCC2C2!important;
          border-radius: 16px;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 120%;
          letter-spacing: -0.02em;
          color: #222222!important;
          padding: 24px;
        }
      }
      .collapse.show {
        padding-top: 8px;
        .accordion-body {
          border: 1px solid #BCC2C2;
          border-radius: 16px;
          padding: 24px;
        }
      }
    }

    @media screen and (max-width: $mobile_breakpoint) {
      .accordion-item {
        .accordion-header {
          .accordion-button {
            font-size: 12px;
            padding: 12px 16px;
          }
        }
        .collapse.show {
          .accordion-body {
            padding: 12px 16px;
          }
        }
      }
    }
  }
}

.collapsible{
  a.collapsed{
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: #222222;
    text-decoration: none;
    padding-bottom: 8px;
  }
  ol {
    padding: 0;
    li{
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: -0.02em;
      color: #222222;
    }
  }

}
.modal-cc-auth{
  z-index: 99999;
}
.text-discount{
  color: var(--cgx-primary) !important;
}
