/* You can add global styles to this file, and also import other style files */

// Font
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.5.0/css/all.min.css');

@import 'ngx-toastr/toastr';

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';


@import 'slick';
@import 'override';

.block-ui-wrapper{
  background: rgba(0, 0, 0, 0.05) !important;
}
.block-ui-wrapper.active{
  display: flex!important;
  justify-content: center;
  align-items: center;
}

.cx-toast{
  background: #EE777C;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  button.toast-close-button{
    margin-right: 16px;
  }
  .toast-title{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    border-bottom: 1px solid #ffffff;
    padding:12px 16px;
  }
  .toast-message{
    padding:12px 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    /* or 21px */

    letter-spacing: -0.02em;

    /* Text/Active */

    color: #FFFFFF;
  }
}

body {
  padding-top: 97px;
  font-size: 12px;
  @media all and (max-width: $mobile_breakpoint) {
    padding-top: 56px;
  }
}

//SVG SEAT MAP
#seatmap{
  display: flex;
  width: 100%;
  padding: 64px 0;
  svg{
    cursor: pointer;
    width: 100%;
    height: auto;
    .sections {
      transition: all .3s;
      transform-origin: 50% 50%;
      &.selected{
        transform: scale(1.05);
        -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
        filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
      }
      &.hover-sold{
        fill: #eeeeee;
      }
    }

  }
}
